@media(max-width: 1500px) {
    .what-virtualx:before,
    .demo-account:before,
    .unique-feature .content:after,
    .benefits:before {
        display: none;
    }
}

@media(min-width: 1201px) and(max-width: 1700px) {
    .carousel figure.orbit-figure .orbit-caption {
        left: 50px;
        strong {
            font-size: 100px;
        }
    }
    .fade-title {
        font-size: 150px;
    }
}

@media (min-width: 768px) {
    .vx-table tr,
    .vx-table th,
    .vx-table td {
        white-space: nowrap;
    }
}

@media(min-width: 768px) and(max-width: 1200px) {
    h1 {
        font-size: 32px;
        line-height: normal;
        margin-bottom: 35px;
    }
    h2 {
        font-size: 32px;
        margin: 0 0 15px;
    }
    h6 {
        font-size: 20px;
        line-height: normal;
    }
    p {
        font-size: 17px;
        line-height: normal;
    }
    .carousel .icon {
        font-size: 30px;
    }
    .carousel figure.orbit-figure {
        .orbit-caption {
            left: 25px;
            width: calc(100% - 50px);
            ul.number-list li {
                font-size: 17px;
                padding: 0 10px;
            }
            strong {
                font-size: 40px;
                margin-bottom: 10px;
                display: block;
            }
            h2 {
                font-size: 20px;
                font-weight: 500;
                line-height: normal;
            }
            ul li {
                font-size: 15px;
                padding: 0 10px 0 20px;
            }
        }
    }
    .carousel figure.orbit-figure .orbit-caption .button {
        font-size: 18px;
    }
    .carousel .orbit-controls {
        top: inherit;
        transform: inherit;
        bottom: 0;
        right: 50px;
    }
    .carousel img {
        height: auto;
    }
    .overview-column {
        height: 400px;
        .hover-cont {
            left: 25px;
            right: 25px;
            width: auto;
        }
    }
    .demo-account {
        h2 {
            text-align: center;
        }
        .tab-bg {
            height: auto;
            >img {
                left: 0;
                width: 700px;
                right: 0;
                margin: auto;
            }
        }
        .left-area {
            margin: 58px 0 0 2px;
            width: 100%;
            text-align: center;
            img {
                width: 410px;
            }
        }
        .right-area {
            width: 100%;
            margin: 115px 0 30px;
            text-align: center;
            .title {
                font-size: 20px;
                line-height: normal;
            }
        }
        .tabs-panel {
            padding: 0;
        }
        .tab-links {
            margin: 30px 0 0;
            text-align: center;
            top: 0;
            .tabs li a {
                padding: 25px 35px 0;
                font-size: 14px;
            }
        }
    }
    .fade-title {
        font-size: 90px;
    }
    .unique-feature {
        padding-bottom: 50px;
        &:before {
            display: none;
        }
    }
    footer {
        [class*=large-].cell {
            width: calc(50% - 1.875rem);
            margin-bottom: 15px;
        }
        .form-wrap {
            left: 0;
        }
    }
    .custom-gutter-2 .large-4 {
        width: calc(33.33% - 66px);
    }
    .price .select-plan:after {
        right: 55px;
    }
    .price ul li {
        font-size: 14px;
    }
    .search-result .item-block:nth-child(2n+2) {
        padding-left: 50px;
    }
    .search-result .item-block {
        padding-bottom: 30px;
    }
    .search-result .item-block:nth-child(n+3) {
        padding-top: 30px;
    }
}

@media (min-width: 1025px) {
    body.is-reveal-open {
        padding-right: 17px;
    }
    footer .grid-margin-x {
        >.large-2 {
            width: calc(16.66667% - 1.875rem);
        }
        >.large-3 {
            width: calc(25% - 1.875rem);
        }
        >.large-4 {
            width: calc(33.33333% - 1.875rem);
        }
    }
}

@media (max-width: 1024px) {
    .fixed-bg {
        background-attachment: scroll;
    }
    .sub-footer {
        margin-bottom: 0;
    }
    footer .form-wrap {
        margin-bottom: 34px;
    }
}

@media (max-width: 767px) {
    .table-scroll table {
        width: 100%;
    }
    nav.off-canvas.is-open {
        transform: translate(0, 0)
    }
    .navigation-wrap>div {
        margin: 0 0 0 20px;
    }
    header .login {
        margin: 0 0 0 20px;
    }
    header .logo img {
        max-width: 180px;
    }
    .price .badge {
        top: -7px;
    }
    .position-right {
        width: 200px;
    }
    .overview {
        padding: 20px 0 60px;
    }
    nav {
        padding-bottom: 15px;
        min-width: 200px;
        .menu li a {
            padding: 7px 0px;
            font-size: 18px;
            i {
                padding-right: 35px;
                font-size: 20px;
            }
        }
    }
    h1 {
        font-size: 22px;
        line-height: normal;
        margin-bottom: 25px;
    }
    h2 {
        font-size: 30px;
        margin: 0 0 10px;
    }
    h6 {
        font-size: 18px;
        line-height: normal;
    }
    p {
        font-size: 16px;
        line-height: normal;
    }
    .custom-gutter .medium-6 {
        width: calc(100% - 10px);
    }
    .overview-column {
        height: 320px;
        .hover-cont {
            left: 20px;
            width: calc(100% - 40px);
        }
    }
    .box-content {
        width: 100%;
        padding: 40px 15px 20px;
    }
    .milestones {
        h2 {
            padding: 0 0 0 70px;
            margin: 0 0 10px;
            line-height: normal;
        }
        i {
            font-size: 50px;
        }
        .count {
            font-size: 60px;
            line-height: normal;
            .red {
                font-size: 60px;
                line-height: normal;
            }
        }
        .count-wrap {
            margin: 0 0 15px;
        }
    }
    #search-block {
        display: none;
        position: absolute;
        right: 187px;
    }
    .demo-account {
        h2 {
            text-align: center;
        }
        padding: 30px 0;
        .tab-bg {
            height: auto;
            >img {
                left: 0;
                width: 300px;
                right: 0;
                margin: auto;
            }
        }
        .left-area {
            margin: 25px 0 0 2px;
            width: 100%;
            text-align: center;
            img {
                width: 177px;
            }
        }
        .right-area {
            width: 100%;
            margin: 35px 0 0;
            text-align: center;
            .title {
                font-size: 20px;
                line-height: normal;
            }
        }
        .tabs-panel {
            padding: 0;
        }
        .tab-links {
            margin: 30px 0 0;
            text-align: center;
            top: 0;
            .tabs li a {
                padding: 25px 7px 0;
                font-size: 14px;
            }
        }
    }
    .fade-title {
        font-size: 35px;
    }
    .unique-feature {
        padding-bottom: 40px;
        .header-area div {
            padding: 15px 0;
            font-size: 18px;
        }
        p {
            font-size: 16px;
            line-height: normal;
        }
        .footer-area {
            height: auto;
            padding: 20px 15px 10px;
        }
    }
    .service {
        padding: 25px 0;
        .title-image {
            margin: 0 0 10px;
        }
    }
    .unique-feature:before {
        display: none;
    }
    .sub-text {
        font-size: 20px;
        line-height: normal;
        margin: 0 0 20px;
    }
    .plans-price {
        padding: 30px 0;
        .sub-text {
            margin: 0 0 30px;
        }
        em {
            padding: 5px 0 0;
            line-height: normal;
        }
    }
    .price {
        .card-price {
            font-size: 30px;
            sup {
                font-size: 20px;
            }
        }
        .card-divider h4 {
            font-size: 20px;
        }
        .card-section {
            .content {
                padding: 20px 0 5px;
            }
            strong {
                font-size: 20px;
            }
        }
    }
    .benefits {
        padding: 30px 0 0;
        .benefit-box {
            margin-top: 0;
        }
        .title {
            font-size: 30px;
            margin: 0 0 10px;
        }
    }
    ul.def-list {
        margin: 0 0 20px;
        li {
            line-height: normal;
            font-size: 16px;
            &:after {
                top: 5px;
            }
        }
    }
    .client .client-list ul {
        margin: 0 -5px;
        flex-wrap: wrap;
        li {
            margin: 0 10px 10px;
            width: calc(50% - 20px);
        }
    }
    footer {
        padding: 20px 0;
        a {
            font-size: 16px;
        }
        .form-wrap {
            position: static;
            margin: 25px 0 0;
        }
    }
    .carousel {
        .orbit-container {
            height: 93.75vw !important;
            .orbit-slide,
            .orbit-figure {
                height: 100%;
                img {
                    height: 100%;
                    object-fit: cover;
                }
                .orbit-caption {
                    .button {
                        padding: 2.5vw 4.5vw;
                        font-size: 4vw;
                    }
                    left: 0;
                    max-width: 100%;
                    padding: 0 15px;
                    strong {
                        font-size: 6vw;
                        margin-bottom: 2vw;
                        display: block;
                    }
                    h2 {
                        font-size: 4vw;
                        line-height: 5.2vw;
                        margin-bottom: 2vw;
                    }
                    ul {
                        margin-bottom: 4vw;
                        &.number-list {
                            font-size: 4vw;
                            li {
                                &:first-child {
                                    padding-left: 0;
                                }
                                padding: 0 10px 0 0;
                            }
                        }
                        li {
                            padding: 0 5px 0 20px;
                        }
                    }
                }
            }
        }
        .orbit-controls {
            right: 15px;
            bottom: 15px;
            font-size: 7vw;
            top: inherit;
            transform: inherit;
            button {
                &+button {
                    margin: 0;
                }
            }
        }
    }
    .carousel .icon {
        font-size: 20px;
        bottom: 0;
    }
    .custom-gutter-2 {
        margin: 0
    }
    .custom-gutter-2 .large-4 {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
    }
    .register,
    .search-result {
        padding: 30px 0;
    }
    .search-result .sr-result .item-block {
        flex: 1 1 100%;
        padding: 0 0 15px;
        border-bottom: solid 1px #514949;
        border-right: 0;
        border-top: 0;
        margin: 0 0 25px;
    }
    .search-result p {
        font-size: 16px;
    }
}

@media (min-width: 640px) and (max-width: 767px) {
    .custom-gutter .medium-6 {
        width: calc(50% - 10px);
    }
}

@media(max-width: 640px) {
    header {
        padding-bottom: 0;
        .navigation-wrap {
            order: 1;
            width: 100%;
            margin: 0;
            margin: 0 0 20px;
            >div {
                margin: 0 0 0 35px;
            }
            .login {
                position: absolute;
                bottom: 13px;
                right: 60px;
            }
            .language-bar {
                flex: 0;
                order: 3;
                top: 0;
            }
        }
        .logo {
            order: 2;
            position: relative;
            top: -20px;
            img {
                width: 60%;
            }
        }
        .login {
            margin: 0 0 0 20px;
        }
        #search-block {
            right: 126px;
            top: -8px;
            bottom: inherit;
        }
        .top-block {
            position: relative;
            flex-wrap: wrap;
            .hamburger-menu {
                position: absolute;
                bottom: 20px;
                right: 0;
            }
        }
        nav {
            margin-top: 62px;
            right: 0;
            .menu.vertical {
                text-align: left;
            }
        }
        &.small {
            .logo {
                img {
                    max-width: inherit;
                }
            }
        }
        .user-welcome {
            top: 0;
        }
        &.secondary-header {
            .hamburger-menu {
                display: block;
            }
            nav.navigation {
                right: -100% !important;
                &.active {
                    right: -10px !important;
                }
            }
            nav {
                position: absolute;
                top: 100%;
                background: #2c2020;
                padding: 15px 10px;
                ul.vertical.menu {
                    flex-direction: column;
                    li {
                        a {
                            color: #fff;
                            padding: 5px 0;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 420px) {
    .client .client-list .owl-stage,
    .client .client-list .owl-item {
        justify-content: center;
    }
}