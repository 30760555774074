//Font
@font-face {
    font-family: 'Bahij Insan';
    src: url('../fonts/BahijInsan.woff2') format('woff2'), url('../fonts/BahijInsan.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

// Default
html {
    height: 100%;
}

body {
    overflow-x: hidden;
    color: #000;
    height: 100%;
}

.margin-0 {
    margin: 0 !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-animation: autofill 0s forwards;
    animation: autofill 0s forwards;
}

@keyframes autofill {
    100% {
        background: transparent;
        color: #fff;
    }
}

@-webkit-keyframes autofill {
    100% {
        background: transparent;
        color: #fff;
    }
}

@mixin placeholder {
     ::-webkit-input-placeholder {
        @content
    }
     :-moz-placeholder {
        @content
    }
     ::-moz-placeholder {
        @content
    }
     :-ms-input-placeholder {
        @content
    }
}

$highlight-color: #cb022c;
$red-font: #cd202f;
.button {
    background-color: $highlight-color;
    border-radius: 50px;
    border: none;
    min-width: 107px;
    font-size: 16px;
    padding: 10px 15px 11px;
    margin-bottom: 0;
    &:hover {
        background-color: #261b1b;
    }
    &:focus {
        background-color: #261b1b;
    }
    &.dark-hover {
        &:hover {
            background: #8c0a25;
        }
        &:focus {
            background-color: #8c0a25;
        }
    }
}

h1 {
    font-size: 39px;
    line-height: 51px;
    text-align: center;
    font-weight: normal;
    margin-bottom: 70px;
}

h2 {
    margin: 0;
    &.padd-adj {
        padding-bottom: 30px;
    }
}

h6 {
    font-size: 20px;
}

p {
    font-size: 18px;
    line-height: 25px;
    a {
        font-weight: bold;
        text-decoration: underline;
    }
}

a:hover {
    color: #cb022c;
}

ul {
    li {
        margin: 0 0 7px;
    }
    &.list-none {
        list-style: none;
        margin: 0;
    }
}

footer {
    ul.def-list {
        li {
            &:after {
                top: 10px;
            }
        }
    }
}

ul.def-list {
    margin: 0;
    li {
        margin: 0 0 7px;
        padding: 0 0 0 25px;
        list-style: none;
        position: relative;
        &:after {
            width: 11px;
            height: 8px;
            content: "";
            position: absolute;
            left: 0;
            top: 8px;
            background: url(../img/list-style-default.png) no-repeat;
        }
    }
}

.list-style {
    list-style: none;
    margin: 0;
    li {
        padding-left: 20px;
        position: relative;
        &:before {
            content: "";
            background: url(../img/list-style.png) no-repeat;
            position: absolute;
            left: 0;
            top: 8px;
            width: 6px;
            height: 7px;
        }
    }
}

.custom-gutter {
    margin: 0 -5px;
    .medium-6 {
        margin: 5px;
        width: calc(50% - 10px);
    }
}

.img-overlay {
    position: relative;
    &:after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: rgba(44, 32, 32, 0.7);
    }
    >div {
        position: relative;
        z-index: 1;
    }
}

// Header
header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background: #fff;
    z-index: 9999;
    padding: 10px 0 18px;
    box-shadow: 0px 2px 19px -4px rgba(0, 0, 0, 0.36);
    .user-welcome {
        position: relative;
        top: -15px;
        transition: all ease 0.5s;
        font-size: 14px;
        font-weight: bold;
        span {
            color: #cd202f;
        }
        a {
            color: #000;
        }
    }
    &.secondary-header {
        .language-bar {
            flex: 1 0 50%;
        }
        .hamburger-menu {
            display: none;
        }
        nav {
            margin-top: 0 !important;
            position: static;
            background: transparent;
            min-width: inherit;
            height: auto;
            padding: 0;
            ul.vertical.menu {
                height: auto !important;
                flex-direction: row;
                li {
                    padding: 0 19px;
                    &.active {
                        a {
                            color: #cd202f;
                            background: transparent;
                        }
                    }
                    a {
                        color: #000000;
                        padding: 0;
                        &:hover {
                            color: #cd202f;
                            &:before {
                                display: none;
                            }
                        }
                    }
                }
            }
            &:before {
                display: none;
            }
        }
    }
    .logo {
        align-self: flex-end;
        img {
            width: auto;
            transition: all ease 0.5s;
        }
    }
    &.small {
        .user-welcome {
            top: -5px;
        }
        .logo {
            img {
                max-width: 120px;
            }
        }
        .navigation-wrap .language-bar {
            top: -5px;
        }
    }
    .top-block {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
    }
    .search {
        cursor: pointer;
        color: #b4b4b4;
        font-size: 20px;
        width: 20px;
        height: 21px;
        outline: 0;
        .search-top {
            position: relative;
            top: -3px;
        }
    }
    .header-form {
        padding-top: 18px;
        &.active {
            &:after {
                @include css-triangle(14px,
                #cb022c,
                up);
                position: absolute;
                bottom: -18px;
                left: 0;
                right: 0;
                margin: auto;
            }
        }
    }
    .login {
        height: 37px;
        position: relative;
        margin: 0;
        a {
            &:focus {
                background-color: #cb022c;
            }
            &.active {
                background-color: #261b1b;
            }
        }
    }
    .form-wrap {
        display: none;
        position: absolute;
        min-width: 221px;
        top: calc(100% + 18px);
        padding-top: 18px;
        left: 50%;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
        z-index: 99;
        right: 0;
        height: 200px;
        overflow: hidden;
        .item {
            position: absolute;
            transition: all ease-in 0.5s;
            &.hide-to-top {
                transform: translateY(-120%);
            }
            &.show-from-bottom {
                transform: translateY(0%);
            }
        }
        .login-form {
            input {
                margin: 0 0 25px;
            }
        }
        .forgot-pwd-form {
            transform: translateY(120%);
        }
        button {
            color: #fff;
        }
        span {
            &.float-left {
                margin: 4px 0 0;
                font-size: 11px;
                a {
                    color: #fff;
                    padding: 0;
                    position: static;
                    &:hover {
                        background: transparent;
                    }
                }
            }
        }
        .title {
            font-size: 15px;
            font-weight: bold;
            margin: 0 0 10px;
        }
        .bottom-field {
            margin: 20px 0 0;
            p,
            a {
                font-weight: 300;
                font-size: 12px;
                margin: 0;
                color: $white;
            }
            a {
                border-bottom: solid 1px #d84161;
            }
        }
    }
}

input[readonly]~label {
    font-size: 12px;
    -webkit-transform: translate3d(0, -12px, 0);
    transform: translate3d(0, -12px, 0);
}

.account-setting {
    .large-9.medium-8.cell {
        width: 100%;
    }
}

input,
textarea {
    &:valid {
        ~label {
            font-size: 12px;
            transform: translate3d(0, -12px, 0);
            &.checkmark {
                transform: inherit;
                opacity: 1;
            }
        }
    }
}

.form-wrap {
    *[data-focus="false"] {
        &~label {
            transform: translate3d(0, 5px, 0);
        }
    }
    *[data-focus="true"],
    *[data-focus="false"]:focus {
        &~label {
            opacity: 0.3;
            font-size: 12px;
            -webkit-transform: translate3d(0, -12px, 0);
            transform: translate3d(0, -12px, 0);
        }
    }
    &.form-adj {
        input {
            margin: 0 0 79px;
            &:focus {
                margin: 0 0 79px;
            }
        }
        select,
        select:focus {
            margin-bottom: 0 !important;
        }
        .checkbox {
            margin: 0 0 30px;
        }
    }
    .info {
        display: block;
        font-size: 12px;
        font-weight: 300;
        color: rgba(255, 255, 255, 0.3);
        margin: 0 0 15px;
    }
    padding-top: 15px;
    padding-bottom: 15px;
    .cell {
        position: relative;
    }
    input,
    textarea,
    select {
        font-family: "Roboto", sans-serif;
        background: transparent;
        color: #fff;
        border: none;
        border-bottom: solid 1px #514949;
        -webkit-box-shadow: none;
        box-shadow: none;
        margin-bottom: 15px;
        padding: 0.1875rem 0.125rem;
        font-size: 15px;
        font-weight: 300;
        line-height: normal;
        height: 34px;
        resize: none;
        &:focus {
            @extend input;
        }
    }
    input {
        &[type="radio"] {
            cursor: pointer;
            position: relative;
            left: -5px;
            opacity: 0;
            height: auto;
            margin: 0;
            &:not(:checked) {
                &+.checkmark:after {
                    opacity: 0;
                    -webkit-transform: scale(0);
                    transform: scale(0);
                }
            }
        }
        &[type='checkbox'] {
            margin: 0 !important;
        }
    }
    select {
        background: url(../img/dropdown.png) no-repeat center right 12px !important;
        color: rgba(255, 255, 255, 0.3) !important;
        option {
            color: #000 !important;
        }
    }
    .checkmark {
        position: static;
        cursor: pointer;
        &:before {
            content: '';
            position: absolute;
            left: 0;
            top: 8px;
            width: 14px;
            height: 14px;
            border: 1px solid #d8d8d8;
            border-radius: 100%;
            background: #fff;
        }
        &:after {
            content: '';
            width: 6px;
            height: 6px;
            background: #9e2323;
            position: absolute;
            top: 12px;
            left: 4px;
            border-radius: 100%;
            -webkit-transition: all 0.2s ease;
            transition: all 0.2s ease;
        }
    }
    label {
        // transform: translate3d(0, 5px, 0);
        position: absolute;
        color: #fff;
        opacity: 0.3;
        top: 0;
        transition: 0.2s ease all;
        -moz-transition: 0.2s ease all;
        -webkit-transition: 0.2s ease all;
        &.checkmark {
            transform: inherit;
        }
        &.select-label {
            top: -20px;
        }
    }
    input,
    textarea {
        padding-bottom: 0;
        position: relative;
        z-index: 9;
    }
    &.red {
        background: $highlight-color;
        color: $white;
        label {
            opacity: 1;
        }
        input,
        textarea {
            border-bottom: solid 1px #fa0034;
            &:focus {
                @extend input;
                border-bottom: solid 1px #fa0034;
            }
            &:focus,
            &:valid {
                ~label {
                    opacity: 0.8;
                }
            }
        }
    }
    .checkbox {
        display: flex;
        align-items: center;
        input {
            height: auto;
            margin: 0;
            &:focus {
                outline: 0;
            }
        }
        label {
            transform: inherit !important;
            position: static;
        }
    }
    .panel {
        font-size: 16px;
        display: inline-block;
        width: 100%;
        margin: 0 0 25px;
        border: solid 1px #d7d7d7;
        &.card-details {
            display: none;
            &.active {
                display: inline-block;
            }
        }
        &.marg-adj {
            input {
                margin: 0 0 20px;
            }
            .panel-body.padding {
                padding: 25px 15px;
            }
        }
        .panel-head {
            background: #9e2323;
            color: #fff;
            padding: 7px 15px;
            font-size: 16px;
        }
        .panel-body {
            float: left;
            width: 100%;
            padding: 0 15px;
            &.padding {
                padding: 40px 15px;
            }
            .plan-type {
                border-bottom: solid 1px #d7d7d7;
                display: block;
                padding: 15px 15px 10px;
                margin: 0 -15px;
            }
            .plan-price {
                display: inline-block;
                width: 100%;
                padding: 15px 0 10px;
            }
            .payment-type {
                display: flex;
                padding: 12px 0;
                justify-content: space-between;
                .radio-wrap {
                    position: relative;
                    display: flex;
                    align-items: center;
                    padding: 0 0 0 5px;
                }
            }
        }
    }
}

.hamburger-menu {
    @include hamburger(#2c2020,
    #2c2020,
    41px,
    21px,
    5px,
    3);
}

nav {
    z-index: 999;
    position: absolute;
    right: 0.9375rem;
    min-width: 260px;
    background: #2c2020;
    margin-top: 18px;
    -webkit-transition: all ease-in 0.5s;
    transition: all ease-in 0.5s;
    padding: 35px 0;
    &.position-right {
        min-height: 100%;
    }
    ul.vertical.menu {
        overflow-y: auto;
    }
    &.off-canvas {
        background: #2c2020;
        &.is-open {
            transform: translate(-15px, 0);
        }
    }
    .close-btn {
        position: absolute;
        right: 15px;
        color: #564e4c;
        top: 20px;
        font-size: 22px;
        z-index: 9;
        cursor: pointer;
    }
    &:before {
        content: "";
        position: absolute;
        background: #261b1b;
        left: 0;
        top: 0;
        bottom: 0;
        width: 72px;
    }
    .menu {
        li {
            a {
                padding: 20px 0;
                font-size: 20px;
                position: relative;
                z-index: 1;
                outline: 0;
                &:focus {
                    color: #fff;
                }
                &:hover {
                    color: #cb022c;
                }
                i {
                    font-size: 25px;
                    padding: 0 55px 0 25px;
                }
                &:hover {
                    &:before {
                        content: "";
                        position: absolute;
                        left: 0;
                        top: 0;
                        bottom: 0;
                        width: 3px;
                        background: #cb022c;
                    }
                }
            }
        }
    }
    a {
        color: #fff;
        &:hover {
            color: #cb022c;
        }
    }
}

.navigation-wrap {
    -ms-flex-item-align: end;
    align-self: flex-end;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    >div {
        margin: 0 0 0 35px;
    }
    .language-bar {
        flex: 1 0 100%;
        position: relative;
        top: -15px;
        transition: all ease 0.5s;
        ul {
            display: flex;
            margin: 0;
            align-items: center;
            justify-content: flex-end;
            font-size: 14px;
            li {
                list-style: none;
                margin: 0;
                padding: 0 10px 0 0;
                &.ar {
                    font-family: 'Bahij Insan';
                    font-size: 16px;
                }
                &+li {
                    padding: 0 0 0 10px;
                    position: relative;
                    &:before {
                        content: "";
                        position: absolute;
                        top: 5px;
                        bottom: 5px;
                        width: 2px;
                        left: 0;
                        background: #261b1b;
                    }
                }
                a {
                    color: #261b1b;
                    font-weight: 700;
                    &:hover {
                        color: #cb022c;
                    }
                }
                &.active {
                    a {
                        color: #cb022c;
                    }
                }
            }
        }
    }
}

.red {
    color: $red-font;
}

.carousel {
    position: relative;
    figure.orbit-figure {
        .orbit-caption {
            z-index: 9;
            max-width: 1005px;
            top: 50%;
            bottom: inherit;
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
            left: 150px;
            padding: 0;
            h2 {
                font-size: 37px;
                font-weight: 500;
                line-height: 45px;
                margin-bottom: 0;
            }
            .button {
                border-bottom: solid 4px #a10324;
                padding: 13px 22px 11px;
                font-size: 22px;
                font-weight: 500;
                &:hover {
                    border-bottom-color: #261b1b;
                }
                &:focus {
                    border-bottom: solid 4px #261b1b;
                }
            }
            strong {
                line-height: normal;
                font-size: 176px;
            }
            ul {
                list-style: none;
                padding: 4px 0;
                margin: 0 0 25px;
                border-top: solid 1px rgba(255, 255, 255, 0.2);
                border-bottom: solid 1px rgba(255, 255, 255, 0.2);
                &.tick-list {
                    li {
                        &+li {
                            &:before {
                                font-family: 'virtualx';
                                content: "\e904";
                                position: absolute;
                                width: 15px;
                                height: 15px;
                                left: 0;
                            }
                        }
                    }
                }
                &.number-list {
                    counter-reset: section;
                    font-weight: 700;
                    font-size: 22px;
                    li {
                        padding: 0 25px;
                        margin: 0;
                        display: -webkit-inline-box;
                        display: -ms-inline-flexbox;
                        display: inline-flex;
                        -webkit-box-orient: horizontal;
                        -webkit-box-direction: normal;
                        -ms-flex-flow: row wrap;
                        flex-flow: row wrap;
                        -webkit-box-align: center;
                        -ms-flex-align: center;
                        align-items: center;
                        a {
                            color: #fff;
                            display: flex;
                            align-items: center;
                            &:focus {
                                color: #fff;
                                outline: 0;
                            }
                        }
                        &:before {
                            margin: 0 10px 0 0;
                            background: $highlight-color;
                            border-radius: 50%;
                            width: 25px;
                            height: 25px;
                            display: inline-block;
                            text-align: center;
                            content: counter(section);
                            counter-increment: section;
                            line-height: 26px;
                        }
                        i {
                            margin: 0 10px 0 0;
                        }
                    }
                }
                li {
                    margin: 0;
                    display: inline-block;
                    vertical-align: middle;
                    padding: 0 40px;
                    position: relative;
                    &:first-child {
                        padding-left: 5px;
                    }
                    &:last-child {
                        padding-right: 10px;
                    }
                }
            }
        }
    }
    .orbit-controls {
        position: absolute;
        right: 20%;
        z-index: 99;
        top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        font-size: 45px;
        .orbit-previous {
            -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
        }
        button {
            display: -webkit-inline-box;
            display: -ms-inline-flexbox;
            display: inline-flex;
            position: static;
            padding: 0;
            cursor: pointer;
            color: #7c7574;
            -webkit-transform: translateY(0);
            transform: translateY(0);
            &:hover,
            &:focus {
                background: transparent;
            }
            &+button {
                margin-left: 25px
            }
        }
    }
    .icon {
        position: absolute;
        bottom: 15px;
        left: 0;
        right: 0;
        margin: auto;
        z-index: 9;
        text-align: center;
        font-size: 60px;
        &.to-next {
            width: 60px;
            margin: auto;
            color: #fff;
            cursor: pointer;
        }
        -webkit-animation: floater infinite 3s ease-in-out;
        animation: floater infinite 3s ease-in-out;
        -webkit-animation-fill-mode: forwards;
        animation-fill-mode: forwards;
    }
}

.overview {
    padding: 50px 0 80px;
}

.overview-column {
    height: 495px;
    position: relative;
    overflow: hidden;
    a {
        display: block;
        height: 100%;
    }
    i {
        font-size: 80px;
        vertical-align: bottom;
        margin: 0 10px 0 0;
    }
    .icon-diagonal-arrow {
        position: absolute;
        right: 20px;
        top: 20px;
        margin: 0;
        color: #fff;
        font-size: 20px;
        z-index: 9;
        display: none;
    }
    img {
        height: 100%;
        width: 100%;
        -o-object-fit: cover;
        object-fit: cover;
    }
    .hover-cont {
        left: 58px;
        width: 485px;
        position: absolute;
        top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        z-index: 9;
        h3 {
            color: #fff;
            margin: 0 0 30px;
        }
        p {
            color: #fff;
        }
    }
    &:hover {
        .icon-diagonal-arrow {
            display: block;
        }
    }
    .hover-background {
        position: absolute;
        width: 100%;
        height: 100%;
        background: $highlight-color;
    }
}

.fixed-bg {
    background-attachment: fixed;
}

.what-virtualx {
    background-image: url('../img/startup-photos-7356.jpg');
    background-size: cover;
    position: relative;
    z-index: 9;
    padding: 0 0 50px;
    &:before {
        content: "";
        position: absolute;
        background: url(../img/sec-before.png) no-repeat;
        width: 125px;
        height: 186px;
        background-size: contain;
        top: -80px;
        left: 30px;
        z-index: 9;
        -webkit-animation: rotate infinite 30s linear;
        -moz-animation: rotate infinite 30s linear;
        animation: rotate infinite 30s linear;
    }
}

.box-content {
    background: #cb022c;
    width: 478px;
    color: #fff;
    padding: 60px 25px 30px;
    position: relative;
    top: -40px;
}

.sub-text {
    margin: 0 0 40px;
    display: block;
    font-size: 24px;
    color: #9a9a9a;
}

.milestones {
    color: #fff;
    i {
        position: absolute;
        top: 0;
        left: 0;
        font-size: 100px;
        display: inline-block;
        vertical-align: middle;
    }
    h2 {
        position: relative;
        padding: 15px 0 0 125px;
        margin: 0 0 40px;
    }
    .count-wrap {
        margin: 0 0 30px;
    }
    .count {
        font-size: 100px;
        font-weight: bold;
        line-height: 100px;
        display: inline-block;
    }
    .red {
        @extend .count;
    }
    span {
        font-size: 20px;
        font-weight: normal;
        display: block;
    }
}

.demo-account {
    &:before {
        content: "";
        position: absolute;
        background: url(../img/sec-before.png) no-repeat;
        width: 125px;
        height: 186px;
        background-size: contain;
        top: -80px;
        right: 30px;
        z-index: 9;
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
        -webkit-animation: rotate infinite 30s linear;
        -moz-animation: rotate infinite 30s linear;
        animation: rotate infinite 30s linear;
    }
    position: relative;
    padding: 60px 0 50px;
    background: #261b1b;
    color: #fff;
    .tabs-content {
        background: transparent;
        border: none;
    }
    .tab-bg {
        height: 630px;
        position: relative;
        >img {
            position: absolute;
            left: -160px;
        }
    }
    .left-area {
        display: inline-block;
        vertical-align: middle;
        position: relative;
        margin: 60px 0 0 13px;
        width: 600px;
    }
    .right-area {
        display: inline-block;
        vertical-align: middle;
        width: 360px;
        color: #fff;
        margin: 0 0 0 155px;
        .title {
            font-size: 100px;
            font-weight: bold;
            line-height: 100px;
            display: inline-block;
            margin: 0 0 10px;
        }
        p {
            margin: 0;
            span {
                color: #7a716f;
            }
        }
        .button {
            margin: 20px 0 0;
        }
    }
    .tab-links {
        position: relative;
        top: -105px;
        .tabs {
            background: transparent;
            border: none;
            li {
                position: relative;
                float: none;
                display: inline-block;
                margin-right: -4px;
                &:before {
                    content: "";
                    position: absolute;
                    height: 3px;
                    background: #574f4d;
                    width: 100%;
                    left: 0;
                    right: 0;
                    top: 0;
                }
                a {
                    background: transparent;
                    border: none;
                    padding: 25px 35px 0;
                    font-size: 18px;
                    color: #fff;
                    position: relative;
                    outline: 0;
                    &:before {
                        content: "";
                        position: absolute;
                        width: 16px;
                        height: 16px;
                        background: #574f4d;
                        border-radius: 50%;
                        top: -7px;
                        left: 0;
                        right: 0;
                        margin: auto;
                    }
                }
                &.is-active {
                    a:before {
                        background: #cb022c;
                    }
                }
            }
        }
    }
}

.fade-title {
    font-size: 250px;
    font-weight: bold;
    opacity: 0.04;
    text-align: center;
}

.unique-feature {
    .content {
        z-index: 3;
        position: relative;
        &:after {
            position: absolute;
            background: url(../img/sec-before.png) no-repeat;
            width: 125px;
            height: 186px;
            background-size: contain;
            bottom: 80px;
            left: 30px;
            z-index: 9;
            content: "";
            -webkit-animation: rotate infinite 30s linear;
            -moz-animation: rotate infinite 30s linear;
            animation: rotate infinite 30s linear;
        }
    }
    &:before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 160px;
        background-color: #fff;
        z-index: 2;
    }
    background-image: url(../img/unique-features.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    color: #fff;
    p {
        font-size: 24px;
        line-height: 40px;
    }
    .feature-cln {
        display: flex;
        flex-direction: column;
    }
    .header-area {
        border: solid 1px rgba(255, 255, 255, 0.1);
        div {
            padding: 30px 0;
            font-size: 25px;
            img {
                vertical-align: middle;
                margin-right: 30px;
            }
            &+div {
                border-top: solid 1px rgba(255, 255, 255, 0.1);
            }
        }
    }
    .footer-area {
        background: #c1032b;
        flex-grow: 1;
        padding: 35px 25px 20px;
        &.even {
            background: #d4032f;
        }
    }
}

.service {
    padding: 50px 0 30px;
    h2 {
        margin-bottom: 0;
    }
    .title-image {
        margin: 0 0 15px;
        img {
            display: inline-block;
            vertical-align: middle;
            width: 65px;
            height: auto;
            margin-right: 15px;
        }
        h6 {
            display: inline-block;
            vertical-align: middle;
            width: calc(100% - 85px);
        }
    }
    p {
        a {
            display: inline-block;
        }
    }
}

.plans-price {
    background: #261b1b;
    color: #fff;
    padding: 70px 0;
    .large-3.medium-6.cell {
        display: flex;
    }
    h1 {
        margin-bottom: 0;
    }
    em {
        display: inline-block;
        padding: 15px 0 0;
        font-size: 14px;
        span {
            color: #cb022c;
        }
    }
    .sub-text {
        margin: 15px 0 60px;
    }
}

.custom-gutter-2 {
    margin: 0 -33px;
}

.custom-gutter-2 .large-4 {
    width: calc(33.33% - 66px);
    margin-left: 33px;
    margin-right: 33px;
}

.price {
    padding: 5px 0 20px;
    position: relative;
    text-align: center;
    border: none;
    transition: all ease 0.3s;
    overflow: visible;
    flex-basis: 100%;
    &:hover {
        background: #cb022c;
        color: #fff;
        .card-price {
            color: #fff;
            sup {
                color: #fff;
            }
        }
        .button {
            background: #261b1b;
            color: #fff;
        }
        .card-icon {
            i {
                color: #fff;
            }
        }
    }
    .badge {
        position: absolute;
        top: -8px;
        right: 30px;
        background: #fd7512;
        border-radius: 0;
        width: 57px;
        height: 31px;
        font-size: 12px;
        padding: 7px 10px;
        line-height: 14px;
        span {
            position: relative;
            z-index: 9;
            display: block;
            &:after {
                content: "";
                border-style: solid;
                border-width: 21px 28.5px 0 28.5px;
                border-color: #fd7412 transparent transparent transparent;
                position: absolute;
                left: -10px;
                right: 0;
                bottom: -17px;
                z-index: -1;
                width: 100%;
            }
        }
        &:before,
        &:after {
            content: "";
            position: absolute;
            top: 0;
            width: 0;
            height: 0;
            border-style: solid;
        }
        &:before {
            left: -7px;
            border-width: 0 0 7px 7px;
            border-color: transparent transparent #e26000 transparent;
        }
        &:after {
            right: -7px;
            border-width: 7px 0 0 7px;
            border-color: transparent transparent transparent #e26000;
        }
    }
    .card-divider {
        background: transparent;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        h4 {
            width: 100%;
            font-size: 24px;
        }
    }
    .select-plan {
        position: relative;
        ul {
            li {
                cursor: pointer;
                display: none;
                &:first-child {
                    display: block;
                }
            }
        }
        &:after {
            content: "";
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 9px 5.5px 0 5.5px;
            border-color: #ffffff transparent transparent transparent;
            position: absolute;
            right: 70px;
            top: 13px;
            cursor: pointer;
        }
        &.active {
            &:after {
                border-width: 0 5.5px 9px 5.5px;
                border-color: transparent transparent #ffffff transparent;
            }
        }
    }
    .card-icon {
        min-height: 111px;
        border-bottom: solid 1px rgba(0, 0, 0, 0.1);
        i {
            display: block;
            width: 100%;
            color: #cb022c;
            font-size: 88px;
        }
        &+.card-section {
            margin: 0 0;
        }
    }
    ul {
        list-style: none;
        margin: 0 0 15px;
        padding: 0;
        li {
            line-height: 35px;
            margin: 0 -45px;
            padding: 0 45px;
            &+li {
                border-top: solid 1px rgba(0, 0, 0, 0.1);
            }
        }
    }
    .card-section {
        padding: 0 35px;
        strong {
            display: block;
            font-size: 24px;
            font-weight: normal;
            margin: 0 0 10px;
        }
        .content {
            padding: 33px 0 10px;
        }
    }
    .card-price {
        font-size: 50px;
        color: #cb022c;
        sup {
            font-size: 25px;
            color: #000;
        }
    }
}

.benefits {
    position: relative;
    &:before {
        content: "";
        position: absolute;
        background: url(../img/sec-before.png) no-repeat;
        width: 125px;
        height: 186px;
        background-size: contain;
        top: -80px;
        right: 30px;
        z-index: 9;
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
        -webkit-animation: rotate infinite 30s linear;
        -moz-animation: rotate infinite 30s linear;
        animation: rotate infinite 30s linear;
    }
    padding: 70px 0 30px;
    .title {
        font-size: 40px;
        margin: 0 0 20px;
    }
    .benefit-box {
        margin-top: 35px;
    }
}

.client {
    padding: 35px 0;
    border-top: solid 1px #f2f2f2;
    margin: 0 0 1px;
    .client-list {
        .owl-stage,
        .owl-item {
            display: inline-flex;
            flex-wrap: wrap;
        }
        .item {
            padding: 10px 17px;
            vertical-align: middle;
            border: solid 1px #dedbdb;
            text-align: center;
            flex: 1 0 auto;
            img {
                max-width: 150px;
                margin: 0 auto;
                filter: grayscale(0);
                &:hover {
                    filter: grayscale(1);
                }
            }
        }
    }
}

footer {
    .cell {
        position: relative;
    }
    padding: 50px 0;
    background-color: #261b1b;
    p {
        margin: 0 0 7px;
        font-size: 18px;
        color: #fff;
    }
    a {
        color: #574f4d;
        font-size: 18px;
    }
    .access {
        margin: 15px 0 0;
    }
    .form-wrap {
        @include placeholder {
            font-size: 13px;
        }
        position: absolute;
        left: 50px;
        right: 0;
        padding: 20px 25px;
        .title {
            margin: 0 0 10px;
            font-size: 24px;
            color: #fff;
        }
        .cell {
            padding: 0;
        }
        .button {
            background-color: #261b1b;
        }
    }
    ul {
        li {
            a {
                &:focus {
                    color: #574f4d;
                    outline: 0;
                }
            }
        }
    }
}

.sub-footer {
    font-size: 12px;
    color: #574f4d;
    margin-bottom: 95px;
    padding: 25px 0;
}

#search-block {
    display: none;
    position: absolute;
    bottom: 18px;
    right: 222px;
    input[type="search"] {
        margin: 0;
        padding-right: 40px;
    }
    span.search-btn {
        position: absolute;
        top: 0;
        right: 0;
        width: 39px;
        height: 39px;
        &:after {
            content: "\e969";
            font-family: 'virtualx' !important;
            speak: none;
            font-style: normal;
            font-weight: normal;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            position: absolute;
            left: 0;
            right: 3px;
            top: 1px;
            bottom: 0;
            line-height: 39px;
            text-align: center;
        }
    }
    input[type="submit"] {
        background: none;
        text-indent: -9999px;
        border: none;
        padding: 0;
        position: relative;
        z-index: 1;
        outline: 0;
        width: 39px;
        height: 39px;
        cursor: pointer;
    }
}

.js-off-canvas-overlay.is-overlay-fixed {
    margin: 0;
}

.unf-padding {
    padding: 35px 0;
}

.full-height {
    min-height: 100%;
}

.dark-bg {
    background: #261b1b;
    color: #fff;
}

.register {
    padding: 70px 0;
    .price {
        ul {
            margin: 0;
        }
        h4 {
            border-bottom: solid 1px #fff;
            width: auto;
        }
        color: #fff;
        background: #cb022c;
        .card-price {
            color: #fff;
        }
        .card-price sup {
            color: #fff;
        }
        .button {
            background: #261b1b;
            color: #fff;
        }
        .plan-detail {
            display: none;
        }
    }
    &.settings {
        background: transparent;
        color: #000;
        input {
            color: #000;
        }
        h2 {
            padding-bottom: 40px;
        }
        .form-wrap {
            .info {
                color: #000;
            }
            label {
                color: #000;
                opacity: 1;
            }
            select {
                color: #000 !important;
            }
        }
    }
}

.search-result {
    padding: 70px 0;
    .sr-result {
        display: flex;
        flex-wrap: wrap;
        .item-block {
            flex: 0 1 50%;
        }
    }
    h5 {
        margin: 0 0 20px;
        a {
            color: #cb022c;
            font-size: 20px;
            font-weight: normal;
        }
    }
    p {
        line-height: 25px;
        font-size: 18px;
        color: rgba(255, 255, 255, 0.3);
    }
    mark {
        background-color: #9a9a9a;
    }
    .item-block {
        padding-bottom: 65px;
        &:nth-child(2n+1) {
            padding-right: 100px;
            border-right: solid 1px #514949;
        }
        &:nth-child(2n+2) {
            padding-left: 100px;
        }
        &:nth-child(n+3) {
            padding-top: 65px;
            border-top: solid 1px #514949;
        }
    }
}

#error_explanation .panel {
    border: none;
}

.vx-table {
    border-bottom: none;
    border-color: transparent;
    &.text-center {
        th {
            text-align: center;
        }
    }
    thead,
    tbody {
        border: none;
    }
    tr,
    th,
    td {
        border: solid 1px #d7d7d7;
        padding: 10px 25px;
    }
    tr {
        &:last-child {
            border: solid 1px #d7d7d7;
        }
        i {
            color: #cd202f;
        }
    }
}

.payment {
    .form-wrap {
        input,
        select {
            border-bottom: solid 1px #d7d7d7;
        }
        *[data-focus="false"]:valid~label {
            font-size: 12px;
            -webkit-transform: translate3d(0, -12px, 0);
            transform: translate3d(0, -12px, 0);
        }
    }
}

@keyframes floater {
    0% {
        transform: translate3d(0px, -10px, 0px);
    }
    50% {
        transform: translate3d(0px, 0px, 0px);
    }
    100% {
        transform: translate3d(0px, -10px, 0px);
    }
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

// table responsive
.stacktable {
    width: 100%;
}

.st-head-row {
    padding-top: 1em;
}

.st-head-row.st-head-row-main {
    font-size: 1.5em;
    padding-top: 0;
}

.st-key {
    width: 49%;
    text-align: right;
    padding-right: 1%;
}

.st-val {
    width: 49%;
    padding-left: 1%;
}


/* RESPONSIVE EXAMPLE */

.stacktable.large-only {
    display: table;
}

.stacktable.small-only {
    display: none;
}

.grid-cont .medium-4.cell {
    display: flex;
    flex-wrap: wrap;
    .plan-type {
        border: none;
        a.button {
            margin: 0 0 10px;
        }
    }
}

@media (max-width: 800px) {
    .stacktable.large-only {
        display: none;
    }
    .stacktable.small-only {
        display: table;
    }
}